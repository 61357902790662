/* eslint-disable no-unused-vars */
import React from 'react';
import {Form as AntForm, FormItemProps, FormProps} from 'antd';

/**
 * @param {FormProps} props
 * @return {JSX.Element}
 */
const Form = (props) => {
  const {...rest} = props;

  return <AntForm {...rest}/>;
};

/**
 *
 * @param {FormItemProps} props
 * @return {JSX.Element}
 */
Form.Item = (props) => {
  const {...rest} = props;
  return <AntForm.Item {...rest}/>;
};

Form.useForm = AntForm.useForm;

export default Form;
