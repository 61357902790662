import useFirestore from '../../hooks/useFirestore';
import React, {useEffect, useState} from 'react';
import AdminCrudLayout from '../../layouts/AdminCrudLayout';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, Descriptions, PageHeader, Row, Space} from 'antd';
import EventModal from './EventModal';
import moment from 'moment';
import Typography from '../../components/antd/Typography.js';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {momentSorter} from '../../utils/sorter.ts';
import EventCard from './EventCard.js';
import Search from 'antd/es/input/Search.js';

export const eventCreate = {
  action: 'create',
  title: i18n.t('pages.admin.events.actions.create'),
  event: null
};

export const eventUpdate = (event) => ({
  action: 'update',
  title: i18n.t('pages.admin.events.actions.update'),
  event
});

const eventNone = null;

const Events = () => {
  const {t} = useTranslation();
  const eventStore = useFirestore('events');
  const [events, setEvents] = useState([]);
  const [action, setAction] = useState(eventNone);
  const [reload, setReload] = useState(0);
  const [upcomingQuery, setUpcomingQuery] = useState('');
  const [pastQuery, setPastQuery] = useState('');
  const upcomingEvents = events
    .filter((event) => event.start_datetime.isAfter(moment()))
    .filter((event) => event.matches(upcomingQuery))
    .sort((a, b) => momentSorter(a.start_datetime, b.start_datetime));
  const pastEvents = events
    .filter((event) => event.end_datetime.isBefore(
      moment()) || moment().isBetween(event.start_datetime, event.end_datetime)
    )
    .filter((event) => event.matches(pastQuery))
    .sort((a, b) => momentSorter(a.start_datetime, b.start_datetime, {orderBy: 'desc'}));

  useEffect(() => {
    eventStore?.getAll().then((e) => {
      setEvents(e.sort((a, b) => moment.utc(b.start_datetime).diff(moment.utc(a.start_datetime))));
    });
  }, [eventStore, reload]);

  const Header = () => (
    <PageHeader
      className={'p-0 py-5'}
      title={t('models.events.plural')}
      extra={[
        <Button
          key={'add'}
          size={'large'}
          type={'primary'}
          shape={'circle'}
          icon={<PlusOutlined/>}
          onClick={() => setAction(eventCreate)}
        />
      ]}
    >
      <Descriptions size={'small'} column={3}>
        <Descriptions.Item label={t('pages.admin.events.header.details')}>{events?.length || 0}</Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );

  return (
    <AdminCrudLayout header={<Header/>}>
      <Space direction={'vertical'} style={{width: '100%'}} size={92}>
        <Space direction={'vertical'} style={{width: '100%'}} size={16}>
          <Row>
            <Col xs={24} md={12}>
              <Typography.Title level={4}>{t('pages.admin.events.body.upcoming')}</Typography.Title>
            </Col>
            <Col xs={24} md={12}>
              <Search placeholder={t('actions.search')} allowClear onSearch={setUpcomingQuery} style={{width: '100%'}}/>
            </Col>
          </Row>
          {upcomingEvents.map((event) => <EventCard key={event.id} event={event} setAction={setAction}/>)}
        </Space>
        <Space direction={'vertical'} style={{width: '100%'}} size={16}>
          <Row>
            <Col xs={24} md={12}>
              <Typography.Title level={4}>{t('pages.admin.events.body.past')}</Typography.Title>
            </Col>
            <Col xs={24} md={12}>
              <Search placeholder={t('actions.search')} allowClear onSearch={setPastQuery} style={{width: '100%'}}/>
            </Col>
          </Row>
          {pastEvents.map((event) => <EventCard key={event.id} event={event} setAction={setAction}/>)}
        </Space>
      </Space>
      <EventModal
        width={1024}
        action={action}
        onCancel={() => setAction(eventNone)}
        afterUpdate={() => setReload(reload + 1)}
      />
    </AdminCrudLayout>
  );
};

export default Events;
