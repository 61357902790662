/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {flickrConfig} from '../flickrConfig';

export const Sizes = {
  square: 'Square',
  largeSquare: 'Large Square',
  thumbnail: 'Thumbnail',
  small: 'Small',
  small320: 'Small 320',
  small400: 'Small 400',
  medium: 'Medium',
  medium640: 'Medium 640',
  medium800: 'Medium 800',
  large: 'Large',
  large1600: 'Large 1600',
  large2048: 'Large 2048',
  original: 'Original'
};

const useFlicker = ({photosetId, format = 'json'}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  const load = async () => {
    const getPhotosUrl = new URL(flickrConfig.api);
    getPhotosUrl.search = new URLSearchParams({
      method: 'flickr.photosets.getPhotos',
      api_key: flickrConfig.apiKey,
      photoset_id: photosetId,
      format: format,
      nojsoncallback: 1
    }).toString();

    const photos = await fetch(getPhotosUrl).then(result => result.json());
    const photoIds = photos.photoset.photo.map((p) => p.id);

    const promises = [];
    photoIds.forEach((photoId) => {
      const getSizesUrl = new URL(flickrConfig.api);
      getSizesUrl.search = new URLSearchParams({
        method: 'flickr.photos.getSizes',
        api_key: flickrConfig.apiKey,
        photo_id: photoId,
        format: format,
        nojsoncallback: 1
      }).toString();

      const sizesPromise = fetch(getSizesUrl).then(result => result.json());
      promises.push(sizesPromise);
    });

    return Promise.all(promises);
  };

  const augmentImages = ({images}) => {
    images = images.map((image) => {
      image.getSize = ({sizeLabel = Sizes.thumbnail}) => image.sizes.size.find((s) => s.label === sizeLabel);
      return image;
    });

    return images;
  };

  useEffect(() => {
    (async () => {
      const images = await (async () => {
        const sessionData = sessionStorage.getItem('flickr');
        if (sessionData) {
          return augmentImages({images: JSON.parse(sessionData)});
        } else {
          const currentData = await load();
          sessionStorage.setItem('flickr', JSON.stringify(currentData));
          return augmentImages({images: currentData});
        }
      })();

      setIsLoading(false);
      setImages(images);
    })();
  }, []);

  return {
    images,
    isLoading
  };
};

export default useFlicker;
