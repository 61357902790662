import moment, {Moment, MomentInput} from 'moment';
import {Link} from 'react-router-dom';
import React from 'react';
import {Badge, Tag} from 'antd';
import {dateTimeFormat} from '../utils/dateTime';
import i18n from '../i18n';
import {searchable} from '../utils/search';
import {FirestoreEventI} from './FirestoreEvent.js';

export enum eventStateKeys {
  active = 'active',
  inactive = 'inactive'
}

export type eventStatesI = {
  [key in eventStateKeys]: {
    value: string;
    label: JSX.Element;
    badge: (text: string) => JSX.Element,
    tag: JSX.Element;
  };
};

export const eventStates: eventStatesI = {
  active: {
    value: 'active',
    label: <><Badge status={'success'}/> {i18n.t('models.events.states.active')}</>,
    badge: (text) => <span><Badge status={'success'} text={text}/></span>,
    tag: <Tag color="green">{i18n.t('models.events.states.active').toLowerCase()}</Tag>
  },
  inactive: {
    value: 'inactive',
    label: <><Badge status={'error'}/> {i18n.t('models.events.states.inactive')}</>,
    badge: (text) => <span><Badge status={'error'} text={text}/></span>,
    tag: <Tag color="orange">{i18n.t('models.events.states.inactive').toLowerCase()}</Tag>
  }
};

export interface EventI {
  id: string,
  start_datetime: Moment,
  end_datetime: Moment,
  ticket_url: string,
  title: string,
  venue: string,
  venue_address: string,
  venue_url: string,
  state: eventStateKeys,
}

const Event = ({
  id,
  start_datetime,
  end_datetime,
  ticket_url,
  title,
  venue,
  venue_address,
  venue_url,
  state,
}: FirestoreEventI) => (searchable(
  Object.freeze({
    id,
    start_datetime: moment(start_datetime.toDate()),
    end_datetime: moment(end_datetime.toDate()),
    ticket_url,
    title,
    venue,
    venue_address,
    venue_url,
    state,
  } as EventI),
  {
    searchableProps: ['title', 'venue', 'venue_address', 'state'],
  }
));


export const eventTableColumns = {
  id: () => ({
    title: '#',
    dataIndex: 'id',
    key: 'id',
  }),
  title: () => ({
    title: i18n.t('models.events.attributes.title'),
    dataIndex: 'title',
    key: 'title',
    sorter: (a: { title: string }, b: { title: string }) => a.title.localeCompare(b.title),
    width: 128
  }),
  startDatetime: () => ({
    title: i18n.t('models.events.attributes.startDatetime'),
    dataIndex: 'start_datetime',
    key: 'start_datetime',
    render: (startDatetime: Moment) => startDatetime.format(dateTimeFormat),
    sorter: (a: MomentInput, b: { start_datetime: Moment }) => b.start_datetime.diff(a),
    width: 128
  }),
  endDatetime: () => ({
    title: i18n.t('models.events.attributes.endDatetime'),
    dataIndex: 'end_datetime',
    key: 'end_datetime',
    render: (endDatetime: Moment) => endDatetime.format(dateTimeFormat),
    sorter: (a: MomentInput, b: { start_datetime: Moment }) => b.start_datetime.diff(a),
    responsive: ['lg'],
  }),
  ticketUrl: () => ({
    title: i18n.t('models.events.attributes.ticketUrl'),
    dataIndex: 'ticket_url',
    key: 'ticket_url',
    render: (ticketUrl: string) => <Link to={{pathname: ticketUrl}} target={'_blank'}>{ticketUrl}</Link>,
    sorter: (a: { ticket_url: string }, b: { ticket_url: string }) => a.ticket_url.localeCompare(b.ticket_url),
    responsive: ['lg'],
  }),
  venue: () => ({
    title: i18n.t('models.events.attributes.venue'),
    dataIndex: 'venue',
    key: 'venue',
    render: (venue: string) => venue,
    sorter: (a: { venue: string }, b: { venue: string }) => a.venue.localeCompare(b.venue),
    responsive: ['lg'],
  }),
  venueAddress: () => ({
    title: i18n.t('models.events.attributes.venueAddress'),
    dataIndex: 'venue_address',
    key: 'venue_address',
    render: (venueAddress: string) => venueAddress,
    sorter: (a: { venue_address: string }, b: { venue_address: string }) => a.venue_address.localeCompare(b.venue_address),
    responsive: ['lg'],
  }),
  venueURL: () => ({
    title: i18n.t('models.events.attributes.venueUrl'),
    dataIndex: 'venue_url',
    key: 'venue_url',
    render: (venueUrl: string) => <Link to={{pathname: venueUrl}} target={'_blank'}>{venueUrl}</Link>,
    sorter: (a: { venue_url: string }, b: { venue_url: string }) => a.venue_url.localeCompare(b.venue_url),
    responsive: ['lg'],
  }),
  state: () => ({
    title: i18n.t('models.events.attributes.state'),
    dataIndex: 'state',
    key: 'state',
    render: (state: eventStateKeys) => (
      state === eventStates.active.value
        ? eventStates.active.tag
        : eventStates.inactive.tag
    ),
    sorter: (a: { state: eventStateKeys }, b: { state: eventStateKeys }) => a.state.localeCompare(b.state),
    width: 128
  })
};

export const defaultEventTableColumns = () => [
  eventTableColumns.title(),
  eventTableColumns.startDatetime(),
  eventTableColumns.endDatetime(),
  eventTableColumns.ticketUrl(),
  eventTableColumns.venue(),
  eventTableColumns.venueAddress(),
  eventTableColumns.venueURL(),
  eventTableColumns.state(),
];

export default Event;
