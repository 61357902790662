/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from 'react';
import {FirebaseContext} from '../contexts/FirebaseContext';

const useFunction = ({region = 'europe-west6', functionName}) => {
  const firebaseContext = useContext(FirebaseContext);
  const [firebaseFunction, setFirebaseFunction] = useState({func: undefined});

  useEffect(() => {
    if (firebaseContext.initialized) {
      const func = firebaseContext.firebase.app().functions(region).httpsCallable(functionName);
      setFirebaseFunction({func});
    }
  }, [firebaseContext.initialized, functionName]);

  if (!functionName) {
    return undefined;
  }

  return firebaseFunction.func;
};

export default useFunction;
