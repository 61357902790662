/* eslint-disable no-unused-vars */
import React from 'react';
import {Button as AntButton, ButtonProps} from 'antd';

/**
 * @param {ButtonProps} props
 * @return {JSX.Element}
 */
const Button = (props) => {
  const {...rest} = props;

  return <AntButton {...rest}/>;
};

export default Button;
