import {RecordingI, recordingStateKeys, recordingStates} from './Recording';

export interface FirestoreRecordingI {
  id: string,
  name: string,
  state: recordingStateKeys
}

const FirestoreRecording = ({
  id,
  name,
  state
}: FirestoreRecordingI): RecordingI => {

  return Object.freeze({
    id,
    name: name || '',
    state: state || recordingStates.open,
  });
};


export default FirestoreRecording;
