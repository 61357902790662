import * as React from 'react';
import {useEffect, useState} from 'react';
import useFirestore from '../../../hooks/useFirestore';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import Container from '../../../layouts/Container';
import Title from '../../../components/Title';
import EventCard from './EventCard.js';
import moment from 'moment';

const EventList = () => {
  const eventStore = useFirestore('events');
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading && eventStore) {
      (async () => {
        try {
          setIsLoading(() => true);
          const e = await eventStore.getAll();
          const filteredSorted = [...e.filter((a) => a.start_datetime.isAfter(moment.now()) && a.state === 'active')];
          filteredSorted.sort((a, b) => moment.utc(a.start_datetime).diff(moment.utc(b.start_datetime)));
          setEvents(filteredSorted);
        } finally {
          setIsLoading(() => false);
        }
      })();
    }
  }, [eventStore]);

  const Events = ({events, ...props}) => {
    return (
      <div className={'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-8'} {...props}>
        {!events.length && (
          <Card title={`Uuuups... ${String.fromCodePoint(0x1F622)}`}>
            Aktuell finden keine Shows statt. Bitte schauen sie später nochmal vorbei um unsere nächsten Konzerte
            nicht zu verpassen.
          </Card>
        )}
        {events.map((event) => <EventCard key={event.id} event={event}/>)}
      </div>
    );
  };

  return (
    <Container>
      <Title>
        <div className={'flex items-center gap-4'}>
          <ion-icon name={'ticket-outline'}/>
          Shows
        </div>
      </Title>
      {isLoading && <div className={'flex justify-center'}><Loader/></div>}
      {!isLoading && <Events events={events}/>}
    </Container>
  );
};

export default EventList;
