import {Link} from 'react-router-dom';
import {HomeRoutes} from '../pages/home/HomeRouter.js';
import {Avatar, Button, Image, Popover} from 'antd';
import firebase from 'firebase/compat/app';
import {UserOutlined} from '@ant-design/icons';
import * as React from 'react';
import useFirestore from '../hooks/useFirestore.js';
import logo from '../assets/mikeric_logo.png';

const Header = () => {
  const isAuthenticated = useFirestore('isAuthenticated');

  const Logo = () => (
    <Image
      alt={'Mike Eric Logo'}
      className={'p-3'}
      style={{width: '64px', height: 'auto'}}
      src={logo}
      preview={false}
    />);

  return (
    <div className={'flex justify-between'}>
      <div>
        <Link to={HomeRoutes.index()}><Logo/></Link>
      </div>
      <div>
        {
          isAuthenticated && (
            <Popover
              placement={'topLeft'} title={'User'}
              content={<Button type={'link'} onClick={() => firebase.auth().signOut()}>Sign Out</Button>}
              trigger={'click'}
            >
              <Avatar size={'large'} icon={<UserOutlined/>}/>
            </Popover>
          )
        }
      </div>
    </div>
  );
};

export default Header;
