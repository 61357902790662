import firebase from 'firebase/compat/app';
import {Timestamp} from '@firebase/firestore';
import {eventStates} from './Event';

export interface FirestoreEventI {
  id: string,
  start_datetime: Timestamp,
  end_datetime: Timestamp,
  ticket_url: string,
  title: string,
  venue: string,
  venue_address: string,
  venue_url: string,
  state: string,
}

const FirestoreEvent = ({
  id,
  start_datetime,
  end_datetime,
  ticket_url,
  title,
  venue,
  venue_address,
  venue_url,
  state,
}: FirestoreEventI) => {

  return Object.freeze({
    id,
    start_datetime: firebase.firestore.Timestamp.fromDate(start_datetime.toDate()),
    end_datetime: firebase.firestore.Timestamp.fromDate(end_datetime.toDate()),
    ticket_url: ticket_url || '',
    title: title || '',
    venue: venue || '',
    venue_address: venue_address || '',
    venue_url: venue_url || '',
    state: state || eventStates.inactive,
  });
};


export default FirestoreEvent;
