import {useState} from 'react';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';

const useAuth = ({initialized}) => {
  const [authUser, setAuthUser] = useState();
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  if (initialized) {
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setAuthUser(u);
      } else {
        setAuthUser(undefined);
      }

      setIsAuthenticating(false);
    });
  }

  return {
    authUser,
    isAuthenticated: Boolean(authUser),
    isAuthenticating
  };
};

export default useAuth;
