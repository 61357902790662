import * as React from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import AdminMenu from './AdminMenu';
import Events from './Events';
import Header from '../../components/Header.js';

const EventsPage = () => (
  <>
    <AdminLayout header={<Header/>} sider={<AdminMenu/>}>
      <Events/>
    </AdminLayout>
  </>
);

export default EventsPage;
