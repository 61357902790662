import React from 'react';
import {Layout, Spin} from 'antd';
import useFirestore from '../hooks/useFirestore';
import Login from '../pages/admin/Login';
import useNofollow from '../hooks/useNofollow.js';

const {Header, Content, Sider} = Layout;

const AdminLayout = ({header, sider, children}) => {
  useNofollow();

  const isAuthenticated = useFirestore('isAuthenticated');
  const isAuthenticating = useFirestore('isAuthenticating');

  return (
    <Layout className={'min-h-screen'}>
      <Header>
        {header}
      </Header>
      <Layout>
        {isAuthenticated && (
          <Sider breakpoint={'lg'}>
            {sider}
          </Sider>
        )}
        <Layout className={'p-5'}>
          <Content className={'flex justify-center items-center'}>
            {isAuthenticating && <Spin/>}
            {!isAuthenticating && isAuthenticated && children}
            {!isAuthenticating && !isAuthenticated && <Login/>}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
