import 'firebase/firestore';
import baseRepository from './baseRepository';
import Recording from '../models/Recording';
import {QueryDocumentSnapshot} from '@firebase/firestore';
import {FirestoreRecordingI} from '../models/FirestoreRecording';


const recordingRepository = () => {
  const fromFirestore = (snapshot: QueryDocumentSnapshot<FirestoreRecordingI>) => {
    return Recording({
      ...snapshot.data(),
    });
  };

  return {
    ...baseRepository({fromFirestore, collectionPath: 'recordings'})
  };
};

export default recordingRepository;
