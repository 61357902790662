import React from 'react';
import {Badge, Tag} from 'antd';
import i18n from '../i18n';
import {FirestoreTaskI} from './FirestoreTask';

export enum taskStateKeys {
  open = 'open',
  done = 'done'
}

export type taskStatesI = {
  [key in taskStateKeys]: {
    value: string;
    label: JSX.Element;
    tag: JSX.Element;
  };
};

export const taskStates: taskStatesI = {
  open: {
    value: 'open',
    label: <><Badge status={'warning'}/> {i18n.t('models.tasks.states.open')}</>,
    tag: <Tag color="orange">{i18n.t('models.tasks.states.open').toLowerCase()}</Tag>
  },
  done: {
    value: 'done',
    label: <><Badge status={'success'}/> {i18n.t('models.tasks.states.done')}</>,
    tag: <Tag color="green">{i18n.t('models.tasks.states.done').toLowerCase()}</Tag>
  }
};

export interface TaskI {
  id: string,
  name: string,
  recording_id: string,
  state: taskStateKeys,
}

const Task = ({
  id,
  name,
  recording_id,
  state
}: FirestoreTaskI): TaskI => {
  return (Object.freeze({
    id,
    name,
    recording_id,
    state
  }));
};

export const taskTableColumns = {
  id: () => ({
    title: '#',
    dataIndex: 'id',
    key: 'id',
  }),
  name: () => ({
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name)
  }),
  recordingId: () => ({
    title: 'Recording ID',
    dataIndex: 'recording_id',
    key: 'recording_id',
    sorter: (a: { recording_id: string }, b: { recording_id: string }) => a.recording_id.localeCompare(b.recording_id)
  }),
  state: () => ({
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    render: (state: string) => (
      state === taskStates.open.value
        ? taskStates.open.label
        : taskStates.done.label
    ),
    sorter: (a: { state: string }, b: { state: string }) => a.state.localeCompare(b.state)
  })
};

export const defaultTaskTableColumns = () => [
  taskTableColumns.name(),
];

export default Task;
