/* eslint-disable no-unused-vars */
import {Col, FormProps, Row} from 'antd';
import Form from '../antd/Form';
import Input from '../antd/Input';
import Button from '../antd/Button';
import React from 'react';

/**
 *
 * @param {FormProps & {isLoading: boolean}} props
 * @return {JSX.Element}
 * @constructor
 */
const ContactForm = (props) => {
  const {isLoading, ...rest} = props;

  return (
    <Form
      layout={'vertical'}
      {...rest}
    >
      <Row gutter={8}>
        <Col xs={24} lg={12}>
          <Form.Item name={'email'} label={'E-Mail Adresse'} rules={[{required: true}]}>
            <Input autoComplete={'email'} placeholder={'bb.king@blues.com'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} lg={12}>
          <Form.Item name={'name'} label={'Name'}>
            <Input autoComplete={'name'} placeholder={'BB King'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} lg={12}>
          <Form.Item name={'streetAddress'} label={'Strasse'}>
            <Input autoComplete={'street-address'} placeholder={'Bealestrasse 1'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} md={4}>
          <Form.Item name={'postalCode'} label={'PLZ'}>
            <Input autoComplete={'postal-code'} placeholder={'3810'}/>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name={'addressLevel2'} label={'Ort'}>
            <Input autoComplete={'address-level2'} placeholder={'Memphishausen'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} lg={12}>
          <Form.Item name={'tel'} label={'Telefonnummer'}>
            <Input autoComplete={'tel'} placeholder={'+41 (0)00 000 00 00'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row className={'hidden'} gutter={8}>
        <Col xs={24} lg={12}>
          <Form.Item name={'fax'} label={'Fax'}>
            <Input autoComplete={'off'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} lg={12}>
          <Form.Item name={'message'} label={'Nachricht'} rules={[{required: true}]}>
            <Input.TextArea placeholder={'Liebe Mike Eric Band...'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <Button
            type={'primary'}
            htmlType={'submit'}
            loading={isLoading}
            disabled={isLoading}
          >
            Senden
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
