import useFirestore from '../../hooks/useFirestore';
import React, {useEffect} from 'react';
import firebase from 'firebase/compat/app';

const Login = () => {
  const ui = useFirestore('ui');

  useEffect(() => {
    if (ui) {
      const header = document.querySelector('header');
      const firebaseUiLink = document.createElement('LINK');
      firebaseUiLink.type = 'text/css';
      firebaseUiLink.rel = 'stylesheet';
      firebaseUiLink.href = 'https://www.gstatic.com/firebasejs/ui/4.8.1/firebase-ui-auth.css';
      header.appendChild(firebaseUiLink);

      ui.start('#firebaseui-auth-container', {
        signInSuccessUrl: '/admin',
        signInOptions: [{
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          disableSignUp: {
            status: true,
          },
        }],
      });
    }
  }, [ui]);

  return <div id={'firebaseui-auth-container'} className={'w-full h-full'}/>;
};

export default Login;
