import {BrowserRouter, Route, Routes} from 'react-router-dom';
import FirestoreProvider from './contexts/FirebaseContext';
import * as React from 'react';
import {AdminRouter} from './pages/admin/AdminRouter';
import './extensions/arrays';
import './i18n.ts';

import moment from 'moment';
import 'moment/locale/de-ch';
import IndexPage from './pages/home/IndexPage.js';

moment.locale('de_CH');

const head = document.querySelector('head') as HTMLHeadElement;
const reCaptcha = document.createElement('SCRIPT') as HTMLScriptElement;
reCaptcha.src = 'https://www.google.com/recaptcha/api.js?render=6Lf1K78cAAAAAF9yrkkOGiwdaKho0QqQjFOeOB0e';
head.appendChild(reCaptcha);

const App = () => (
  <FirestoreProvider>
    <BrowserRouter>
      <Routes>
        <Route path={'admin/*'} element={<AdminRouter/>}/>
        <Route path={'/'} element={<IndexPage/>}/>
      </Routes>
    </BrowserRouter>
  </FirestoreProvider>
);

export default App;
