import 'firebase/firestore';
import {QueryDocumentSnapshot} from '@firebase/firestore';
import baseRepository from './baseRepository';
import Task from '../models/Task';
import {FirestoreTaskI} from '../models/FirestoreTask';


const taskRepository = () => {
  const fromFirestore = (snapshot: QueryDocumentSnapshot<FirestoreTaskI>) => {
    return Task({
      ...snapshot.data(),
    });
  };

  const respository = baseRepository({fromFirestore, collectionPath: 'tasks'});

  const getByRecordingId = async (recordingId: number) => {
    const snapshot = await respository.collection().where('recording_id', '==', recordingId).get();
    return snapshot.docs.map((doc) => doc.data());
  };

  return {
    ...respository,
    getByRecordingId
  };
};

export default taskRepository;
