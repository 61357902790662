import moment, {Moment} from 'moment';

export const dateTimeFormat = 'DD.MM.YYYY HH:mm';

export interface CalendarEntryModelType {
  startDateTime: Moment,
  endDateTime: Moment
}

export interface CalendarEntryType {
  model: CalendarEntryModelType,
  summary?: string,
  uid?: string,
  url?: string,
  description?: string,
  location?: string
}

export const toIcalDateTime = (moment: Moment, skipTimezone = false) => {
  const year = moment.utc().format('YYYY');
  const month = moment.utc().format('MM');
  const day = moment.utc().format('DD');
  const hour = moment.utc().format('HH');
  const minute = moment.utc().format('mm');
  const second = moment.utc().format('ss');
  return `${year}${month}${day}T${hour}${minute}${second}${skipTimezone ? '' : 'Z'}`;
};

export const calendarEntry = ({
  model,
  summary = 'Bastille Day Party',
  uid = 'uid1@example.com',
  url = 'https://www.mikeric.ch',
  description = '',
  location = ''
}: CalendarEntryType) => {
  return `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//www.mikeric.ch//Mike Eric Blues Band
CALSCALE:GREGORIAN
BEGIN:VTIMEZONE
TZID:Europe/Zurich
LAST-MODIFIED:${toIcalDateTime(moment())}
TZURL:http://tzurl.org/zoneinfo-outlook/Europe/Zurich
X-LIC-LOCATION:Europe/Zurich
BEGIN:DAYLIGHT
TZNAME:CEST
TZOFFSETFROM:+0100
TZOFFSETTO:+0200
DTSTART:19700329T020000
RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU
END:DAYLIGHT
BEGIN:STANDARD
TZNAME:CET
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
DTSTART:19701025T030000
RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:${toIcalDateTime(moment())}
UID:${uid}
DTSTART;TZID=Europe/Zurich:${toIcalDateTime(model.startDateTime)}
DTEND;TZID=Europe/Zurich:${toIcalDateTime(model.startDateTime)}
SUMMARY:${summary}
URL:${url}
DESCRIPTION:${description}
LOCATION:${location.replaceAll(',', '\\,')}
END:VEVENT
END:VCALENDAR`.trim();
};
