const ShortDescription = ({...props}) => {

  return (
    <div className={'container mx-auto px-4 my-20'}>
      <p className={'px-0 md:px-32 lg:px-64 text-xl md:text-2xl font-libre text-center'} {...props}>
        Solider Blues aus dem Herzen der Schweiz. Inspiriert durch die Blues Legenden, die in den amerikanischen Bars und
        Clubs für Unterhaltung sorgten, entführt Mike Eric die Zuhörer aus dem Alltag um mit ihnen einen unvergesslichen
        Abend zu verbringen.
      </p>
    </div>
  );
};


export default ShortDescription;
