import * as React from 'react';

const Title = ({level = 1, children, ...props}) => {
  const classNames = [];

  if (level === 1) {
    classNames.push('text-2xl', 'md:text-3xl', 'mb-8', 'font-bold');
  }

  return (
    <div className={classNames.toStringNames()} {...props}>
      {children}
    </div>
  );
};

export default Title;
