import React from 'react';

const DefaultLayout = ({children, classNames = [], ...props}) => {
  const defaultClassNames = ['min-h-screen'];

  return (
    <div className={classNames.concat(defaultClassNames).join(' ')} {...props}>
      {children}
    </div>
  );
};

export default DefaultLayout;
