import {Menu} from 'antd';
import {CalendarOutlined, CustomerServiceOutlined} from '@ant-design/icons';
import useSelectedKeys from '../../hooks/useSelectedKeys';
import {AdminRoutes} from './AdminRouter';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const AdminMenu = () => {
  const {t} = useTranslation();
  const selectedKeys = useSelectedKeys();

  const items = [
    {
      label: <Link to={AdminRoutes.events()}>{t('models.events.plural')}</Link>,
      key: '/admin/events',
      icon: <CalendarOutlined/>
    },
    {
      label: <Link to={AdminRoutes.recordings()}>{t('models.recordings.plural')}</Link>,
      key: '/admin/recordings',
      icon: <CustomerServiceOutlined/>
    },
  ];

  return (
    <Menu
      selectedKeys={selectedKeys}
      style={{height: '100%', borderRight: 0}}
      mode={'inline'}
      theme={'dark'}
      items={items}
    />
  );
};

export default AdminMenu;
