import React, {useState} from 'react';
import Container from '../../../layouts/Container';
import Title from '../../../components/Title';
import Form from '../../../components/antd/Form';
import useFunction from '../../../hooks/useFunction';
import {Col, Row} from 'antd';
import Alert from '../../../components/antd/Alert';
import ContactForm from '../../../components/forms/ContactForm';

const Contact = () => {
  const [form] = Form.useForm();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const emailSender = useFunction({functionName: 'emailSender'});

  const submit = (values) => {
    setIsLoading(true);
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6Lf1K78cAAAAAF9yrkkOGiwdaKho0QqQjFOeOB0e', {action: 'submit'}).then((token) => {
        emailSender({...values, token})
          .then((response) => {
            setSuccess(response);
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    });
  };

  const Success = ({rowProps, colProps, alertProps}) => (
    <Row gutter={8} {...rowProps}>
      <Col xs={24} lg={12} {...colProps}>
        <Alert type={'success'} {...alertProps}/>
      </Col>
    </Row>
  );

  const Error = ({rowProps, colProps, alertProps}) => (
    <Row gutter={8} {...rowProps}>
      <Col xs={24} lg={12} {...colProps}>
        <Alert type={'error'} {...alertProps}/>
      </Col>
    </Row>
  );

  return (
    <div className={'bg-gray-200 py-1'}>
      <Container>
        <Title>
          <div className={'flex items-center gap-4'}>
            <ion-icon name={'mail-outline'}/>
            Kontakt
          </div>
        </Title>
        {!!success && (
          <Success
            alertProps={{message: 'Ihre Nachricht wurde erfolgreich versendet. Vielen Dank!'}}
            rowProps={{className: 'mb-8'}}
          />)}
        {!!error && (
          <Error
            alertProps={{message: 'Beim Versenden Ihrer Nachricht ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal.'}}
            rowProps={{className: 'mb-8'}}
          />
        )}
        {!!(!error && !success) && (
          <ContactForm
            form={form}
            isLoading={isLoading}
            onFinish={submit}
          />
        )}
      </Container>
    </div>
  );
};

export default Contact;
