export const searchable = (object: { [key: string]: any } = {}, config: { searchableProps?: string[] }) => {
  let {searchableProps = []} = config;

  const matches = (query: string) => {
    if (!query) {
      return true;
    }

    return !!Object.entries(object).find(([key, value]: [key: string, value: string]) => {
      if (!searchableProps.includes(key)) {
        return false;
      }

      return value.toLowerCase().includes(query.toLowerCase());
    });
  };

  const setSearchableProps = (props: string[] = []) => {
    searchableProps = props;
  };

  const addSearchableProp = (prop: string) => {
    if (prop) {
      searchableProps.push(prop);
    }
  };

  return Object.freeze({
    ...object,
    getSearchableProps: () => searchableProps,
    matches,
    addSearchableProp,
    setSearchableProps
  });
};
