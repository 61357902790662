import {useContext, useEffect, useState} from 'react';
import {FirebaseContext} from '../contexts/FirebaseContext';

const useFirestore = (storeName) => {
  const firebaseContext = useContext(FirebaseContext);
  const [repository, setRepository] = useState();

  useEffect(() => {
    if (firebaseContext.initialized) {
      setRepository(firebaseContext[storeName]);
    }
  }, [firebaseContext, storeName]);

  return repository;
};


export default useFirestore;
