import React from 'react';
import {Badge, Descriptions, Tag} from 'antd';
import i18n from '../i18n';
import {TaskI, taskStates} from './Task';
import {FirestoreRecordingI} from './FirestoreRecording';

export enum recordingStateKeys {
  open = 'open',
  done = 'finished'
}

export type recordingStatesI = {
  [key in recordingStateKeys]: {
    value: string;
    label: JSX.Element;
    tag: JSX.Element;
  };
};

export const recordingStates: recordingStatesI = {
  open: {
    value: 'open',
    label: <><Badge status={'warning'}/> {i18n.t('models.recordings.states.open')}</>,
    tag: <Tag color="orange">{i18n.t('models.recordings.states.open').toLowerCase()}</Tag>
  },
  finished: {
    value: 'finished',
    label: <><Badge status={'success'}/> {i18n.t('models.recordings.states.finished')}</>,
    tag: <Tag color="success">{i18n.t('models.recordings.states.finished').toLowerCase()}</Tag>
  }
};

export interface RecordingI {
  id: string,
  name: string,
  state: string
}

const Recording = ({
  id,
  name,
  state,
}: FirestoreRecordingI): RecordingI => {

  return (Object.freeze({
    id,
    name,
    state
  }));
};


export const recordingTableColumns = {
  id: () => ({
    title: '#',
    dataIndex: 'id',
    key: 'id',
  }),
  name: () => ({
    title: i18n.t('models.recordings.attributes.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name),
    width: '256px',
  }),
  tasks: () => ({
    title: i18n.t('models.recordings.attributes.tasks'),
    dataIndex: 'tasks',
    key: 'tasks',
    width: '512px',
    render: (tasks: TaskI[]) => {
      if (tasks) {
        return (
          <Descriptions size={'small'} column={2} bordered={true}>
            {tasks.map((task) => (
              <React.Fragment key={task.id}>
                <Descriptions.Item span={1} contentStyle={{width: '100%'}}>
                  {task.name}
                </Descriptions.Item>
                <Descriptions.Item span={1}>
                  {taskStates[task.state].tag}
                </Descriptions.Item>
              </React.Fragment>
            ))}
          </Descriptions>
        );
      } else {
        return null;
      }
    }
  }),
  state: () => ({
    title: i18n.t('models.recordings.attributes.state'),
    dataIndex: 'state',
    key: 'state',
    render: (state: string) => (
      state === recordingStates.open.value
        ? recordingStates.open.tag
        : recordingStates.finished.tag
    ),
    sorter: (a: { state: string }, b: { state: string }) => a.state.localeCompare(b.state),
    filters: [
      {text: recordingStates.open.tag, value: recordingStates.open.value},
      {text: recordingStates.finished.tag, value: recordingStates.finished.value}
    ],
    onFilter: (value: string, record: RecordingI) => record.state.indexOf(value) === 0,
    width: 128
  })
};

export const defaultRecordingTableColumns = () => [
  recordingTableColumns.name(),
  recordingTableColumns.tasks(),
  recordingTableColumns.state(),
];

export default Recording;
