import {Carousel, Image} from 'antd';
import logo from '../../../assets/mikeric_logo.png';
import React from 'react';
import useFlicker, {Sizes} from '../../../hooks/useFlickr';
import {HomeRoutes} from '../HomeRouter';
import {Link} from 'react-router-dom';
import Loader from '../../../components/Loader';
import Indicator from '../../../components/Indicator';

const Hero = () => {
  const {images: flickrImages, isLoading: isLoadingImages} = useFlicker({
    photosetId: '72157646939387598'
  });

  const Logo = () => (
    <Image
      alt={'Mike Eric Logo'}
      className={'filter drop-shadow'}
      style={{width: '300px', height: 'auto'}}
      src={logo}
      preview={false}
    />
  );

  const CarouselImages = ({images}) => images.map((image, i) => (
    <div className={'h-screen w-screen'} key={i}>
      <img
        className={'h-screen w-screen object-top object-cover'}
        src={image.getSize({sizeLabel: Sizes.original}).source}
        alt=""
      />
    </div>
  ));

  return (
    <div className={'relative h-screen bg-gray-800'}>
      {isLoadingImages && <Loader indicator={<Indicator color={'#fff'}/>} absolute fullHeight fullWidth/>}
      <div
        className={`absolute h-screen w-screen transition-opacity duration-1000 ease-out ${isLoadingImages ? 'opacity-0' : 'opacity-1'}`}>
        <Carousel>
          {Boolean(flickrImages?.length) && CarouselImages({images: flickrImages})}
        </Carousel>
      </div>
      <div
        className={`absolute p-16 md:p-32 transition-opacity duration-2000 delay-500 ease-out ${isLoadingImages ? 'opacity-0' : 'opacity-1'}`}>
        <Link to={HomeRoutes.index()}><Logo/></Link>
      </div>
    </div>
  );
};

export default Hero;
