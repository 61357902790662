/* eslint-disable no-unused-vars */
import React from 'react';
import {Typography as AntdTypography, TypographyProps} from 'antd';
import {TypographyProps as BaseTypography} from 'antd/lib/typography/Typography';

/**
 *
 * @param {BaseTypography} props
 * @return JSX.Element
 * @constructor
 */
const Typography = (props) => <AntdTypography {...props}/>;

/**
 *
 * @param {TypographyProps.Paragraph} props
 * @return {React.ReactElement<any, any>}
 * @constructor
 */
Typography.Paragraph = (props) => <AntdTypography.Paragraph {...props}/>;

/**
 *
 * @param {TypographyProps.Title} props
 * @return {React.ReactElement<any, any>}
 * @constructor
 */
Typography.Title = (props) => <AntdTypography.Title {...props}/>;

/**
 *
 * @param {TypographyProps.Text} props
 * @return {React.ReactElement<any, any>}
 * @constructor
 */
Typography.Text = (props) => <AntdTypography.Text {...props}/>;

export default Typography;
