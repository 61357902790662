/* eslint-disable no-unused-vars */
import React from 'react';
import {Input as AntInput, InputProps} from 'antd';

/**
 * @param {InputProps} props
 * @return {JSX.Element}
 */
const Input = (props) => {
  const {...rest} = props;

  return <AntInput {...rest}/>;
};

Input.TextArea = AntInput.TextArea;

export default Input;
