import React from 'react';
import {Button, Card, Descriptions, Space} from 'antd';
import Typography from '../../components/antd/Typography.js';
import {eventStates} from '../../models/Event';
import {dateTimeFormat} from '../../utils/dateTime.ts';
import {EditOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {eventUpdate} from './Events.js';
import Link from '../../components/Link.js';

const EventCard = ({event, setAction = () => undefined, ...props} = {}) => {
  const {t} = useTranslation();

  return (
    <Card
      title={(
        <Space direction={'vertical'} size={0}>

          {event.state === eventStates.active.value
            ? eventStates.active.badge(
              <Typography.Title
                style={{display: 'inline'}}
                level={5}
              >
                {event.title}
              </Typography.Title>
            )
            : eventStates.inactive.badge(
              <Typography.Title
                style={{display: 'inline'}}
                level={5}
              >
                {event.title}
              </Typography.Title>
            )}
          <Typography.Text type={'secondary'} style={{fontSize: '0.8em'}}>
            {event.start_datetime.format(dateTimeFormat)} &ndash; {event.end_datetime.format(dateTimeFormat)}
          </Typography.Text>
        </Space>
      )}
      extra={(
        <Button
          type={'primary'}
          icon={<EditOutlined/>}
          onClick={() => setAction(eventUpdate(event))}
        >
          {t('actions.edit')}
        </Button>
      )}
      {...props}
    >
      <Descriptions
        column={{xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2}}
        size={'small'}
        labelStyle={{width: '160px'}}
        contentStyle={{width: '160px'}}
        bordered
      >
        <Descriptions.Item label={t('models.events.attributes.venue')}>
          {event.venue}
        </Descriptions.Item>
        <Descriptions.Item label={t('models.events.attributes.venueAddress')}>
          {event.venue_address}
        </Descriptions.Item>
        <Descriptions.Item label={t('models.events.attributes.venueUrl')}>
          <Link href={event.venue_url} external>{event.venue_url}</Link>
        </Descriptions.Item>
        <Descriptions.Item label={t('models.events.attributes.ticketUrl')}>
          <Link href={event.ticket_url} external>{event.ticket_url}</Link>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default EventCard;
