import React, {useEffect, useRef} from 'react';
import Container from '../../../layouts/Container.js';
import Title from '../../../components/Title.js';
import {Badge, Col, Row} from 'antd';

const AudioVideoTeaser = () => {
  const spotifyContainerRef = useRef();
  const spotifyIFrameAPIRef = useRef();
  const spotifyEmbedControllerRef = useRef();

  useEffect(() => {
    const script = document.createElement('SCRIPT');
    script.src = 'https://open.spotify.com/embed-podcast/iframe-api/v1';
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (spotifyContainerRef.current && !window.onSpotifyIframeApiReady) {
      window.onSpotifyIframeApiReady = (IFrameAPI) => {
        const options = {
          uri: 'spotify:artist:7KyjucRfiu3OtgnuvxbAtQ',
          width: '100%',
          height: '433.125'
        };

        spotifyIFrameAPIRef.current = IFrameAPI;
        IFrameAPI.createController(spotifyContainerRef.current, options, (EmbedController) => {
          spotifyEmbedControllerRef.current = EmbedController;
        });
      };
    }
  }, [spotifyContainerRef.current]);

  return (
    <div className={'bg-gray-200 py-1'}>
      <Container>
        <Row gutter={[
          {lg: 28},
          {xs: 2 * 28, sm: 2 * 28, md: 2 * 28}
        ]}>
          <Col xs={24} lg={12}>
            <Title>
              <div className={'flex items-center gap-4'}>
                <ion-icon name={'musical-notes-outline'}/>
                Reinhören
              </div>
              <Badge>Demo Album 2012</Badge>
            </Title>
            <div ref={spotifyContainerRef}></div>
          </Col>
          <Col xs={24} lg={12}>
            <Title>
              <div className={'flex items-center gap-4'}>
                <ion-icon name={'videocam-outline'}/>
                Reinschauen
              </div>
              <Badge>Promo Blues Night 2014</Badge>
            </Title>
            <div className={'w-full aspect-video shadow-md rounded-xl overflow-hidden'}>
              <iframe
                className={'w-full h-full'}
                src="https://www.youtube-nocookie.com/embed/VF_h9xsiACQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AudioVideoTeaser;
