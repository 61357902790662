import React from 'react';

const Indicator = ({color = 'rgba(0, 0, 0, 0.85)'}) => (
  <div className={'animate-spin'}>
    <svg height="32" width="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        fill={color}
        className="a"
        d="M32,64A32,32,0,1,1,64,32,32,32,0,0,1,32,64ZM32,1.55A30.45,30.45,0,1,0,62.45,32,30.48,30.48,0,0,0,32,1.55Z"/>
      <path
        fill={color}
        d="M30.94.81v43s-1.07-.1-1.86-.1c-6-.39-12.58,4-13.18,10.31-.7,5,4.56,9.13,9.26,8.35,5.91-.64,10.32-6.64,9.55-12.58V18.47c.4-.46,1.23.53,1.65.58,4.76,2.87,9,7.51,9.26,13.33.31,4.55-1.06,9-2.6,13.22,5.16-6.39,6.81-15.72,3.23-23.27-1.84-4.38-6-7.08-8.28-11.15C36.19,8.47,35,4,34.55.77"/>
    </svg>
  </div>
);

export default Indicator;
