/* eslint-disable no-unused-vars */
import React from 'react';
import {Table as AntTable, TableProps} from 'antd';

/**
 * @param {TableProps} props
 * @return {JSX.Element}
 */
const Table = (props) => {
  const {...rest} = props;

  return <AntTable {...rest}/>;
};

export default Table;
