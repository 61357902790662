import {useEffect} from 'react';

const useNofollow = () => {
  useEffect(() => {
    const robots = document.head.querySelector('meta[name="robots"]');

    if (!robots) {
      const meta = document.createElement('META');
      meta.name = 'robots';
      meta.content = 'noindex, nofollow';
      document.head.appendChild(meta);
    }
  }, []);
};

export default useNofollow;
