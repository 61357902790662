const Link = ({href = '', rel = '', target = '', external = false, children} = {}) => {
  if (external) {
    rel = 'noopener nofollow';
    target = '_blank';
  }

  return <a href={href} target={target} rel={rel}>{children}</a>;
};

export default Link;
