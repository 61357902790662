import {TaskI, taskStateKeys, taskStates} from './Task';

export interface FirestoreTaskI {
  id: string,
  name: string,
  recording_id: string,
  state: taskStateKeys,
}

const FirestoreTask = ({
  id,
  recording_id,
  name,
  state
}: FirestoreTaskI): TaskI => {

  return Object.freeze({
    id,
    recording_id: recording_id,
    name: name || '',
    state: state || taskStates.open,
  });
};


export default FirestoreTask;
