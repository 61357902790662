import {Navigate, Route, Routes} from 'react-router-dom';
import React from 'react';
import EventsPage from './EventsPage.js';
import RecordingsPage from './RecordingsPage.js';

export const AdminRoutes = {
  index: () => `/admin`,
  events: () => `/admin/events`,
  recordings: () => `/admin/recordings`
};

export const AdminRouter = () => (
  <Routes>
    <Route path={'events'} element={<EventsPage/>}/>
    <Route path={'recordings'} element={<RecordingsPage/>}/>
    <Route path={'*'} element={<Navigate to={'events'}/>}/>
  </Routes>
);
