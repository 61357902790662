import {Checkbox, Input} from 'antd';
import {MinusCircleOutlined} from '@ant-design/icons';
import {taskStates} from '../../models/Task';

const TaskInput = ({value = {}, onChange, inputProps = {}, checkboxProps = {}, onDelete, ...props}) => {

  const DeleteButton = () => (
    <MinusCircleOutlined
      className="dynamic-delete-button"
      onClick={() => onDelete && onDelete()}
    />
  );

  const onInput = (e) => {
    onChange({
      id: value.id,
      name: e.target.value,
      state: value.state ? value.state : taskStates.open.value,
    });
  };

  const onCheck = (e) => {
    onChange({
      id: value.id,
      name: value.name ? value.name : '',
      state: e.target.checked ? taskStates.done.value : taskStates.open.value,
    });
  };

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}} {...props} >
      <Input
        {...inputProps}
        addonBefore={(value.state === taskStates.open.value || !value.state) && <DeleteButton/>}
        onInput={onInput} value={value.name} disabled={value.state === taskStates.done.value}
      />
      <Checkbox {...inputProps} onChange={onCheck} checked={value.state === taskStates.done.value}/>
    </div>
  );
};

export default TaskInput;
