import 'firebase/firestore';
import Event from '../models/Event';
import baseRepository from './baseRepository';
import {QueryDocumentSnapshot} from '@firebase/firestore';
import {FirestoreEventI} from '../models/FirestoreEvent';

const eventRepository = () => {
  const fromFirestore = (snapshot: QueryDocumentSnapshot<FirestoreEventI>) => {
    return Event({
      ...snapshot.data(),
    });
  };

  return {
    ...baseRepository({fromFirestore, collectionPath: 'events'})
  };
};

export default eventRepository;
