import * as React from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import AdminMenu from './AdminMenu';
import Header from '../../components/Header.js';
import Recordings from './Recordings.js';

const RecordingsPage = () => (
  <>
    <AdminLayout header={<Header/>} sider={<AdminMenu/>}>
      <Recordings/>
    </AdminLayout>
  </>
);

export default RecordingsPage;
