import React, {createContext, useEffect, useState} from 'react';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import eventRepository from '../repositories/eventRepository';
import firebase from 'firebase/compat/app';
import {firebaseConfig} from '../firebaseConfig';
import useAuth from '../hooks/useAuth';
import * as firebaseui from 'firebaseui';
import recordingRepository from '../repositories/recordingRepository';
import taskRepository from '../repositories/taskRepository';

const contextValue = {
  events: eventRepository(),
  recordings: recordingRepository(),
  tasks: taskRepository(),
};

export const FirebaseContext = createContext(contextValue);

const FirestoreProvider = ({children}) => {
  const [initialized, setInitialized] = useState(false);
  const {authUser, isAuthenticated, isAuthenticating} = useAuth({initialized});
  const [ui, setUi] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [functions, setFunctions] = useState({func: undefined});

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      setFunctions({func: firebase.app().functions});
      setUi(new firebaseui.auth.AuthUI(firebase.auth()));
      setInitialized(true);
    }
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        ...contextValue,
        initialized,
        authUser,
        isAuthenticated,
        isAuthenticating,
        ui,
        firebase
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirestoreProvider;
