import firebase from 'firebase/compat/app';

const baseRepository = ({fromFirestore, collectionPath}) => {
  const collection = () => {
    return firebase.firestore().collection(collectionPath).withConverter({
      toFirestore: (data) => {
        if (data.storableProperties) {
          const result = {};
          data.storableProperties.forEach((key) => {
            if (data[key]) {
              result[key] = data[key];
            }
          });
          return result;
        }
        return data;
      },
      fromFirestore
    });
  };

  const getAll = async () => {
    const snapshot = await collection().get();
    return snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  };

  const cleanup = (document) => {
    return Object.entries(document).reduce((acc, [currKey, currValue]) => {
      if (currValue === undefined) {
        return acc;
      }

      acc[currKey] = currValue;
      return acc;
    }, {});
  };

  const add = async (document) => {
    const cleaned = cleanup(document);
    const t = await collection().add(cleaned);

    return {
      ...document,
      id: t.id,
    };
  };

  const drop = async ({id, ...rest}) => {
    await collection().doc(id).delete();
    return {id, ...rest};
  };

  const setDocById = async (id, document) => {
    if (id) {
      const cleaned = cleanup(document);
      await collection().doc(id).set(cleaned);
      return (await collection().doc(id).get()).data();
    }
  };

  return {
    collection,
    getAll,
    add,
    drop,
    setDocById
  };
};

export default baseRepository;
