/* eslint-disable no-unused-vars */
import React from 'react';
import {Alert as AntdAlert, AlertProps} from 'antd';

/**
 *
 * @param {AlertProps} props
 * @return {JSX.Element}
 * @constructor
 */
const Alert = (props) => <AntdAlert {...props}/>;

export default Alert;
