import {useLocation} from 'react-router-dom';

const useSelectedKeys = () => {
  const location = useLocation();
  const getSelectedKeys = (path) => {
    // from path like /users/create we generate keys: /users, /users/create
    const keys = path.split('/').filter((k) => k.length > 0);

    return keys.map((_, index) => `/${keys.slice(0, index + 1).join('/')}`);
  };

  return getSelectedKeys(location?.pathname || '');
};

export default useSelectedKeys;
