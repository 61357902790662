import {Moment} from 'moment';

export const momentSorter = (a: Moment, b: Moment, config = {orderBy: 'asc'}) => {
  if (a.isAfter(b) && config.orderBy === 'asc') {
    return 1;
  }

  if (b.isAfter(a) && config.orderBy === 'asc') {
    return -1;
  }

  return 0;
};
