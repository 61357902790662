import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader';

const init = () => {
  const container = document.getElementById('root') as HTMLElement;
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  );
};

// postpone initiation of app until fonts are active
const webFontConfig = {
  google: {
    families: ['Montserrat', 'Libre Baskerville'],
  },
  classes: false,
  timeout: 1000,
  active: init
};

WebFont.load(webFontConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
