import mikericPackage from '../../../assets/mikeric_package.zip';

const AdditionalInfo = () => {

  const Entry = ({
    href,
    className = 'text-gray-100 mx-3 w-12 h-12 md:w-16 md:h-16 inline-block no-underline',
    rel = 'nofollow',
    icon = <i className="fab fa-facebook"/>,
    target = '_blank',
    ...props
  }) => (
    <a
      className={className}
      href={href}
      rel={rel}
      target={target}
      {...props}
    >
      <div className={'w-full h-full flex justify-center items-center bg-gray-800 hover:bg-gray-700 rounded-full'}>
        {icon}
      </div>
    </a>
  );

  return (
    <div className={'container mx-auto px-4 my-20 text-center'}>
      <Entry icon={<i className="fab fa-facebook-f fa-2x"/>} href={'https://www.facebook.com/cbelephants/'}/>
      <Entry icon={<i className="fab fa-youtube fa-2x"/>} href={'https://www.youtube.com/user/mikericblues'}/>
      <Entry
        icon={<i className="fab fa-itunes-note fa-2x"/>}
        href={'https://itunes.apple.com/us/artist/mike-eric/553440450'}
      />
      <Entry icon={<i className="fas fa-info fa-2x"/>} href={mikericPackage} target={'_self'}/>
    </div>
  );
};

export default AdditionalInfo;
