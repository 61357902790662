import useFirestore from '../../hooks/useFirestore';
import {defaultRecordingTableColumns} from '../../models/Recording';
import Table from '../../components/antd/Table';
import React, {useEffect, useState} from 'react';
import AdminCrudLayout from '../../layouts/AdminCrudLayout';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Descriptions, PageHeader} from 'antd';
import RecordingModal from './RecordingModal.js';
import {useTranslation} from 'react-i18next';
import i18n from '../../i18n.ts';

const recordingCreate = {
  action: 'create',
  title: i18n.t('pages.admin.recordings.actions.create'),
  recording: null
};

const recordingUpdate = (recording) => ({
  action: 'update',
  title: i18n.t('pages.admin.recordings.actions.update'),
  recording
});

const recordingNone = null;

const Recordings = () => {
  const {t} = useTranslation();
  const recordingStore = useFirestore('recordings');
  const taskStore = useFirestore('tasks');
  const [recordings, setRecordings] = useState();
  const [action, setAction] = useState(recordingNone);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    (async () => {
      if (recordingStore) {
        const recs = await recordingStore?.getAll();
        const tasks = await Promise.all(recs.map((rec) => taskStore?.getByRecordingId(rec.id)));

        const extendedRecs = recs.map((rec) => {
          return {
            ...rec,
            tasks: tasks.find((taskList) => !!taskList.find((task) => task.recording_id === rec.id))
          };
        });

        setRecordings(extendedRecs);
      }
    })();
  }, [recordingStore, reload]);

  const columns = [
    ...defaultRecordingTableColumns(),
    {
      title: t('actions.title'),
      dataIndex: 'actions',
      key: 'actions',
      width: '128px',
      render: (_, recording) => (
        <Button
          type={'primary'}
          icon={<EditOutlined/>}
          onClick={() => setAction(recordingUpdate(recording))}
        >
          {i18n.t('actions.edit')}
        </Button>
      )
    }
  ];

  const Header = () => (
    <PageHeader
      className={'p-0 py-5'}
      title={t('models.recordings.plural')}
      extra={[
        <Button
          key={'add'}
          size={'large'}
          type={'primary'}
          shape={'circle'}
          icon={<PlusOutlined/>}
          onClick={() => setAction(recordingCreate)}
        />
      ]}
    >
      <Descriptions size={'small'} column={3}>
        <Descriptions.Item label={t('pages.admin.recordings.header.details')}>
          {recordings?.length || 0}
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );

  return (
    <AdminCrudLayout header={<Header/>}>
      <Table
        tableLayout={'fixed'}
        rowKey={'id'}
        dataSource={recordings}
        columns={columns}
        loading={!recordings}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50', '100'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} events`,
        }}
        scroll={{x: 0}}
      />
      <RecordingModal
        width={1024}
        action={action}
        onCancel={() => setAction(recordingNone)}
        afterUpdate={() => setReload(reload + 1)}
      />
    </AdminCrudLayout>
  );
};

export default Recordings;
