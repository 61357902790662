import {DatePicker, Form, Input, Modal, Select} from 'antd';
import {eventStates} from '../../models/Event';
import FirestoreEvent from '../../models/FirestoreEvent';
import useFirestore from '../../hooks/useFirestore';
import {useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

const {Option} = Select;

const EventModal = ({action, onCancel, afterUpdate = () => undefined, title, ...props}) => {
  const {t} = useTranslation();
  const eventStore = useFirestore('events');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const initialValues = {state: eventStates.active.value};

  if (action?.event) {
    form.setFieldsValue(action.event);
  }

  const doCancel = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const onFinish = async (values) => {
    const document = FirestoreEvent(values);
    if (!action?.event) {
      setLoading(true);
      eventStore.add(document)
        .catch(console.error)
        .finally(() => {
          setLoading(false);
          doCancel();
          afterUpdate();
        });
    } else {
      setLoading(true);
      eventStore.setDocById(action.event.id, document)
        .catch(console.error)
        .finally(() => {
          setLoading(false);
          doCancel();
          afterUpdate();
        });
    }
  };

  return (
    <Modal
      open={action}
      onCancel={doCancel}
      title={title || action?.title}
      onOk={() => form.submit()}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{disabled: loading}}
      okText={t('actions.ok')}
      cancelText={t('actions.cancel')}
      {...props}
    >
      <Form
        initialValues={initialValues}
        form={form}
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={onFinish}
      >
        <Form.Item name="state" label={t('models.events.attributes.state')} rules={[{required: true}]}>
          <Select>
            <Option value={eventStates.active.value}>{eventStates.active.label}</Option>
            <Option value={eventStates.inactive.value}>{eventStates.inactive.label}</Option>
          </Select>
        </Form.Item>
        <Form.Item name="title" label={t('models.events.attributes.title')} rules={[{required: true}]}>
          <Input/>
        </Form.Item>
        <Form.Item name="start_datetime" label={t('models.events.attributes.startDatetime')} rules={[{required: true}]}>
          <DatePicker
            showTime={{
              defaultValue: moment('00:00:00', 'HH:mm:ss'),
              format: 'HH:mm'
            }}
          />
        </Form.Item>
        <Form.Item name="end_datetime" label={t('models.events.attributes.endDatetime')} rules={[{required: true}]}>
          <DatePicker
            showTime={{
              defaultValue: moment('00:00:00', 'HH:mm:ss'),
              format: 'HH:mm'
            }}
          />
        </Form.Item>
        <Form.Item name="ticket_url" label={t('models.events.attributes.ticketUrl')} rules={[
          {
            type: 'url',
            message: 'This field must be a valid url.'
          }
        ]}>
          <Input/>
        </Form.Item>
        <Form.Item name="venue" label={t('models.events.attributes.venue')} rules={[{required: true}]}>
          <Input/>
        </Form.Item>
        <Form.Item name="venue_address" label={t('models.events.attributes.venueAddress')} rules={[{required: true}]}>
          <Input/>
        </Form.Item>
        <Form.Item name="venue_url" label={t('models.events.attributes.venueUrl')} rules={[
          {
            type: 'url',
            message: 'This field must be a valid url.'
          }
        ]}>
          <Input/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EventModal;
