import * as React from 'react';

/**
 * @param {HTMLDivElement} props
 * @return {JSX.Element}
 * @constructor
 */
const Container = (props) => {
  const {className, ...rest} = props;

  const defaultClassNames = ['relative container mx-auto px-2 lg:px-4 my-10 lg:my-20'];

  if (className) {
    defaultClassNames.push(className);
  }

  return (
    <div {...rest} className={defaultClassNames.toStringNames()}/>
  );
};

export default Container;
