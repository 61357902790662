import useIsIntersecting from '../hooks/useIsIntersecting.js';

const Appear = ({children}) => {
  const {isIntersecting, ref, node} = useIsIntersecting();

  const transition = 'transition-all translate-y-16 duration-1000 ease-out';

  if (isIntersecting && node && node.classList.contains('opacity-0')) {
    node.classList.add('opacity-1', 'translate-y-0');
    node.classList.remove('opacity-0', 'translate-y-16');
  }

  return <div ref={ref} className={`opacity-0 ${transition}`}>{children}</div>;
};

export default Appear;
