import {useCallback, useRef, useState} from 'react';

const useIsIntersecting = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  const ref = useCallback((node) => {
    if (node === null) {
      observer.current.disconnect();
      observer.current = null;
    } else {
      if (!observer.current) {
        observer.current = new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        );
        observer.current.observe(node);
        setNode(node);
      }
    }
  }, []);

  return {isIntersecting, ref, node};
};

export default useIsIntersecting;
