import {Button, Form, Input, Modal} from 'antd';
import {recordingStates} from '../../models/Recording';
import useFirestore from '../../hooks/useFirestore';
import {useState} from 'react';
import FirestoreRecording from '../../models/FirestoreRecording';
import {PlusOutlined} from '@ant-design/icons';
import TaskInput from './TaskInput.js';
import FirestoreTask from '../../models/FirestoreTask';
import {differenceBy} from 'lodash/array.js';
import {useTranslation} from 'react-i18next';
import {taskStates} from '../../models/Task';

const formItemLayout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16}
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {span: 16, offset: 0},
    sm: {span: 16, offset: 8}
  }
};

const RecordingModal = ({action, onCancel, afterUpdate = () => undefined, title, ...props}) => {

  const {t} = useTranslation();
  const recordingStore = useFirestore('recordings');
  const taskStore = useFirestore('tasks');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const initialValues = {state: recordingStates.open.value};

  if (action?.recording) {
    form.setFieldsValue(action.recording);
  }

  const doCancel = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const createOrUpdateTasks = async (tasks, document) => {
    const processableTasks = tasks?.filter((task) => !!task && task.name);
    const deletableTasks = tasks && action.recording?.tasks ? differenceBy(action.recording.tasks, tasks, 'id') : [];

    if (processableTasks?.length) {
      processableTasks.forEach((task) => {
        const firestoreTaskModel = FirestoreTask({recording_id: document.id, ...task});
        if (firestoreTaskModel?.id) {
          taskStore.setDocById(firestoreTaskModel.id, firestoreTaskModel);
        } else {
          taskStore.add(firestoreTaskModel);
        }
      });
    }

    if (deletableTasks?.length) {
      deletableTasks.forEach((task) => {
        taskStore.drop(task);
      });
    }
  };

  const onFinish = async ({tasks, ...recording}) => {
    const areAllTasksDone = tasks.every((task) => task.state === taskStates.done.value);
    const firestoreRecordingModel = FirestoreRecording({
      ...recording,
      state: areAllTasksDone ? recordingStates.finished.value : recordingStates.open.value
    });

    if (!action?.recording) {
      setLoading(true);
      try {
        const document = await recordingStore.add(firestoreRecordingModel);
        await createOrUpdateTasks(tasks, document);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        doCancel();
        afterUpdate();
      }

    } else {
      setLoading(true);
      try {
        const document = await recordingStore.setDocById(action.recording.id, firestoreRecordingModel);
        await createOrUpdateTasks(tasks, document);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        doCancel();
        afterUpdate();
      }
    }
  };

  return (
    <Modal
      open={action}
      onCancel={doCancel}
      title={title || action?.title}
      onOk={() => form.submit()}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{disabled: loading}}
      okText={t('actions.ok')}
      cancelText={t('actions.cancel')}
      {...props}
    >
      <Form
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        {...formItemLayout}
      >
        <Form.Item name="name" label={t('models.recordings.attributes.name')} rules={[{required: true}]}>
          <Input/>
        </Form.Item>
        <Form.List name={'tasks'}>
          {(fields, {add, remove}, {errors}) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  key={field.key}
                  label={index === 0 ? t('models.recordings.attributes.tasks') : ''}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    noStyle
                  >
                    <TaskInput inputProps={{placeholder: 'Task'}} onDelete={() => remove(field.name)}/>
                  </Form.Item>
                </Form.Item>
              ))}
              <Form.Item {...formItemLayoutWithOutLabel}>
                <Button
                  type={'button'}
                  onClick={() => add()}
                  icon={<PlusOutlined/>}
                >
                  {t('pages.admin.recordings.modal.action.add')}
                </Button>
                <Form.ErrorList errors={errors}/>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default RecordingModal;
