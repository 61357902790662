import {Spin} from 'antd';
import React from 'react';
import Indicator from './Indicator';

const Loader = ({fullWidth, fullHeight, absolute, indicator = <Indicator/>, ...props}) => {
  const classes = [];

  if (absolute) {
    classes.push('absolute');

    if (fullWidth || fullHeight) {
      classes.push('flex');
    }

    if (fullWidth) {
      classes.push('left-0');
      classes.push('right-0');
      classes.push('justify-center');
    }

    if (fullHeight) {
      classes.push('top-0');
      classes.push('bottom-0');
      classes.push('items-center');
    }
  } else {
    if (fullWidth) {
      classes.push('w-full');
    }

    if (fullHeight) {
      classes.push('w-full');
    }
  }

  return (
    <div className={classes.join(' ')}>
      <Spin indicator={indicator} {...props}/>
    </div>
  );
};

export default Loader;
